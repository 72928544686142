export const string = {
  chatTutorial: [
    "안녕~ 난 너희를 도와줄 디노야. 버들로에 온 걸 환영해!",
    "5개 식당의 퀴즈를 풀면 스탬프를 획득할 수 있어.",
    "스탬프를 다 모으면 리워드를 받을 수 있어!",
    "버드나무 스탬프판을 완성해보자. 선물이 기다리고 있어~.",
    "원하는 곳을 클릭하면 큰 화면으로 볼 수 있어.",
    "가게 아이콘을 눌러볼래?",
  ],
  clickIcon: [
    "버드나무를 찾으러 떠나볼까?",
    "첫 스탬프 획득 성공!",
    "벌써 두 개나 획득했네~. 잘 하고 있어!",
    "이제 두 개밖에 안 남았어.",
    "마지막 하나만 맞추면 상품이 코 앞이야.",
    "버드나무들을 찾아줘서 고마워!",
  ],
  storeInfo: {
    mapHref: "https://naver.me/I5ck4msC",
    stamp: { describe: "퀴즈를 맞추면 스탬프를 획득할 수 있어." },
    store1: {
      title: "소문난 떡볶이 순대",
      address: "서울 구로구 서해안로30길 13",
      href: "https://naver.me/5lZsoKFK",
      describe: "연예인 출신 부부가 운영하는 분식집.\n3대째 내려오는 소스 맛이 일품!\n그때 그 시절 추억을 느껴봐~.",
    },
    store2: {
      title: "먹자풍천민물장어",
      address: "서울 구로구 오류로8길 105",
      href: "https://naver.me/5izeb7Yq",
      describe: "특제 양념으로 맛있게 구워낸 장어구이와\n별미 장어탕을 맛볼 수 있는 곳.\n몸 보신이 필요할 땐 여기!",
    },
    store3: {
      title: "중국성",
      address: "서울 구로구 서해안로26길 12",
      href: "https://naver.me/5ncXJfwI",
      describe: "무려 27년된 오류동 토박이 중국집.\n차돌짬뽕과 탕수육을 강력 추천!",
    },
    store4: {
      title: "고기가 맛있어\n행복한 집",
      address: "서울 구로구 서해안로26길 11-2",
      href: "https://naver.me/xxxh6Bhw",
      describe: "고기를 주문하면 냉면을 주는 가게가 있다?!\n버들시장의 유일무이 쪽갈비집.",
    },
    store5: {
      title: "한라산도새기",
      address: "서울 구로구 서해안로 2318",
      href: "https://naver.me/xScnn3hb",
      describe: "백종원의 3대천왕 방영 흑돼지 오겹살집.\n쫄깃쫄깃한 흑돼지의 식감을 즐겨보세요!",
    },
  },
};
