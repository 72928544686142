const quizeInfo = {
  store1: {
    title: "소문난 떡볶이 순대",
    quize: "Q.‘소문난 떡볶이 순대'\n사장님의 직업은?",
    ex: ["축구선수", "한의사", "연예인"],
    answer: "연예인",
  },
  store2: {
    title: "먹자풍천민물장어",
    quize: "Q.‘먹자풍천민물장어'에서\n판매하지 않는 메뉴는?",
    ex: ["장어탕", "장어구이", "장어덮밥"],
    answer: "장어덮밥",
  },
  store3: {
    title: "중국성",
    quize: "Q.중국성은 오류동에서 몇 년동안\n이어져 온 식당인가요?",
    ex: ["15년", "27년", "39년"],
    answer: "27년",
  },
  store4: {
    title: "고기가 맛있어\n행복한 집",
    quize: "Q.‘고기가 맛있어 행복한 집’에서는 쪽갈비를\n주문하면 ___을 공짜로 맛볼 수 있다.",
    ex: ["냉면", "찌개", "계란찜"],
    answer: "냉면",
  },
  store5: {
    title: "한라산도새기",
    quize: "Q.‘한라산도새기'가 방영된\nTV프로그램은?",
    ex: ["백종원의 골목식당", "백파더", "백종원의 3대천왕"],
    answer: "백종원의 3대천왕",
  },
};

export default quizeInfo;
